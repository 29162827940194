import Popup from "reactjs-popup"
import GalleryImg from "./GalleryImg"
import CloseElem from "../hero/awards/CloseElem"
import GallerySwiper from "./GallerySwiper"

function Gallery({galleries}) {
  return (
    <>
      {galleries.map((gallery, index) =>
        <Popup
          lockScroll
          modal
          key={index}
          trigger={
            <button className="gallery__wrap" >
              <GalleryImg key={index} imgSrc={gallery.img} imgAlt={gallery.alt}/>
            </button>
          }>
          {close => (
            <>
              <a className="popup-close" onClick={close}>
                <CloseElem/>
              </a>
              <GallerySwiper index={index} galleries={galleries}/>
            </>
          )}
        </Popup>
      )}
    </>
  )
}

export default Gallery
