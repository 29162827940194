import React from "react";
import {BrowserRouter, Route, Routes, Navigate} from 'react-router-dom'
import Heroes from "./pages/Heroes";
import Hero from "./pages/Hero";
import NotFound from "./pages/NotFound"
import About from "./pages/About"

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Heroes />}>
        </Route>
        <Route path="/stars/:id" element={<Hero />}>
        </Route>
        <Route path="/about/" element={<About />}>
        </Route>
        <Route path="*" element={<NotFound />}>
        </Route>
        <Route path="*" element={<Navigate to="/" />}>
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App;
