import {useState} from "react"

const useFetching = (request, callback) => {
  const [isLoading, setIsLoading] = useState(false)

  const fetching = async () => {
    setIsLoading(true)
    request()
      .then(res => res.ok ? res.json() : Promise.reject(res))
      .then(res => callback(res))
      .catch(e => {
        console.error(e)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return [fetching, isLoading]
}

export default useFetching
