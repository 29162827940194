function Empty({champNumber}) {
    if (champNumber === 9) {
        return (
            <div className="heroes__empty">
                Пока в «Зените» нет <span className="nowrap">{champNumber}-кратных</span> чемпионов, но есть <span
                className="nowrap">{champNumber + 1}-кратный</span>
            </div>
        )
    } else if (champNumber < 10) {
        return (
            <div className="heroes__empty">
                Пока в «Зените» нет <span className="nowrap">{champNumber}-кратных</span> чемпионов, но есть <span
                className="nowrap">{champNumber + 1}-кратные</span>
            </div>
        )
    } else {
        return (
            <div className="heroes__empty">
                Пока в «Зените» нет <span className="nowrap">{champNumber}-кратных</span> чемпионов
            </div>
        )
    }
}

export default Empty
