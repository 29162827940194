function NotFound() {
  return (
    <div className="page page--404">
      <div className="page__inner">
        <div className="not-found">
          <div className="not-found__code">
            404
          </div>
          <div className="not-found__message">
            Что-то пошло не так
          </div>
        </div>
      </div>
    </div>
  )
}

export default NotFound
