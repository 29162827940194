function Filter({champNumber, changeChampNumber, isGold, isLoading}) {
  const stars = [10, 9, 8, 7, 6, 5, 4, 3, 2, 1]

  return (
    <div className={isGold ? 'filter header__filter filter--gold' : 'filter header__filter'}>
      <div className="filter__label">
        Количество чемпионств
      </div>
      <ul className="filter__stars">
        {
          stars.map(star => 
            <li 
              className={champNumber === star ? 'filter__star active' : 'filter__star'}
              key={star}
              onClick={() => changeChampNumber(star)}
              disabled={isLoading}
            >
              <svg viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M3.6279 17.9444L5.87133 11.0901L0 6.85431H7.25734L9.49924 0L11.7419 6.85431H19L13.1265 11.0894L15.3706 17.9437L9.49924 13.7079L3.6279 17.9444Z" fill="white"/>
              </svg>
            </li>
          )
        }
      </ul>
      <input 
        type="range" 
        className={isGold ? 'filter__range filter__range--gold' : 'filter__range'}
        min="1" 
        max="10"
        step="1"
        value={champNumber}
        onChange={e => changeChampNumber(Number(e.target.value))}
        disabled={isLoading}
      />
    </div>
  )
} 

export default Filter
