function Views ({view, changeView, isLoading}) {
  return (
    <div className="header__views">
      <button 
        type="button" 
        className={view === 'row' ? 'header__view active' : 'header__view'}
        onClick={() => changeView('row')}
        disabled={isLoading}
      >
        <svg viewBox="0 0 34 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="12.8652" y="0.5" width="8" height="24" rx="2.5" stroke="white"/>
          <rect x="25.2305" y="0.5" width="8" height="24" rx="2.5" stroke="white"/>
          <rect x="0.5" y="0.5" width="8" height="24" rx="2.5" stroke="white"/>
        </svg>
      </button>
      <button 
        type="button" 
        className={view === 'grid' ? 'header__view active' : 'header__view'}
        onClick={() => changeView('grid')}
        disabled={isLoading}
      >
        <svg viewBox="0 0 35 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="13.5176" y="0.5" width="8" height="10" rx="3.5" stroke="white"/>
          <rect x="13.5176" y="14.1066" width="8" height="10" rx="3.5" stroke="white"/>
          <rect x="25.8828" y="0.5" width="8" height="10" rx="3.5" stroke="white"/>
          <rect x="25.8828" y="14.1066" width="8" height="10" rx="3.5" stroke="white"/>
          <rect x="1.15234" y="0.5" width="8" height="10" rx="3.5" stroke="white"/>
          <rect x="1.15234" y="14.1066" width="8" height="10" rx="3.5" stroke="white"/>
        </svg>
      </button>
    </div>
  )
}

export default Views
