function CloseElem() {
    return (
        <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="28.2842" width="3" height="40" rx="1.5" transform="rotate(45 28.2842 0)" fill="white"/>
            <rect width="3" height="40" rx="1.5" transform="matrix(-0.707107 0.707107 0.707107 0.707107 2.12109 0)" fill="white"/>
        </svg>

    )
}

export default CloseElem
