import React, {useState, useEffect, useMemo} from "react";
import { Link } from 'react-router-dom'
import Logo from './Logo'

function Header({children, isGold}) {
  const [scroll, setScroll] = useState(0)
  const isFixed = useMemo(() => {
    return scroll > 0
  }, [scroll])

  const className = ['header', isGold ? 'header--gold' : '', isFixed ? 'header--fixed' : '']

  const callback = () => setScroll(window.scrollY)

  useEffect(() => {
    window.addEventListener('scroll', callback)
  }, [])

  return (
    <header className={className.join(' ')}>
      <div className="header__inner">
        <Link 
          to="/"  
          className="header__logo"
        >
          <Logo />
        </Link>
        <div className="header__name">
          Аллея <br />
          славы
        </div>
        {children}
      </div>
    </header>
  )
}

export default Header;
