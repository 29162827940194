import React, { useMemo, forwardRef, useEffect } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Scrollbar, Navigation, Grid, Mousewheel } from 'swiper';
import Card from './Card'
import Arrow from './Arrow'

const Cards = forwardRef(function Cards({heroes, view, isGold, ...args}, swiperRef) {
  useEffect(() => {
    const swiper = swiperRef.current?.swiper

    if (swiper) {
      swiper.on('slideChange', () => sessionStorage.setItem('slideIndex', swiper.activeIndex))

      const SSSlideIndex = sessionStorage.getItem('slideIndex')

      if (SSSlideIndex) {
        swiper.slideTo(SSSlideIndex, 0)
      }
    }
  }, [])

  const isGrid = useMemo(() => {
    return view === 'grid'
  }, [view])

  const config = useMemo(() => {
    const gridOptions ={
      enabled: true,
      grid: {
        rows: 2,
        fill: 'row'
      },
      spaceBetween: 30
    }

    return {
      'row': {
        wrapperClass: 'heroes__wrapper',
        slidesPerView: 1,
        spaceBetween: 45,
        breakpoints: {
          576: {
            slidesPerView: 'auto',
            spaceBetween: 30
          },
          1600: {
            slidesPerView: 'auto',
            spaceBetween: 70
          }
        }
      },
      'grid': {
        wrapperClass: 'heroes__wrapper heroes__wrapper--grid',
        enabled: false,
        slidesPerView: 'auto',
        breakpoints: {
          576: {
            ...gridOptions,
            slidesPerView: 3
          },
          768: {
            ...gridOptions,
            slidesPerView: 4
          },
          992: {
            ...gridOptions,
            slidesPerView: 5
          },
          1600: {
            ...gridOptions,
            slidesPerView: 5,
            spaceBetween: 50
          }
        }
      }
    }[view]
  }, [view])

  if (heroes.length) {
    return (
      <Swiper
        ref={swiperRef}
        className={isGrid ? 'heroes__slider heroes__slider--grid' : 'heroes__slider'}
        modules={[Scrollbar, Navigation, Grid, Mousewheel]}
        scrollbar={{
          el: '.heroes__scrollbar',
          dragClass: 'heroes__scrollbar-drag',
          draggable: true
        }}
        navigation={{
          nextEl: '.heroes__arrow--next',
          prevEl: '.heroes__arrow--prev'
        }}
        mousewheel={true}
        centerInsufficientSlides={true}
        {...config}
        {...args}
      >
        {heroes.map(hero =>
          <SwiperSlide
            key={hero.id}
            className={isGrid ? 'heroes__slide heroes__slide--grid' : 'heroes__slide'}
          >
            <Card
              hero={hero}
              isGrid={isGrid}
              isGold={isGold}
            />
          </SwiperSlide>
        )}
        <div className={isGrid ? 'heroes__scrollbar heroes__scrollbar--grid' : 'heroes__scrollbar'}></div>
        {!isGrid &&
          <div>
            <button type="button" className="heroes__arrow heroes__arrow--prev">
            <Arrow />
            </button>
            <button type="button" className="heroes__arrow heroes__arrow--next">
              <Arrow />
            </button>
          </div>
        }
      </Swiper>
    )
  }
});

export default Cards;
