import {formatDate} from '../../../helpers/helpers'

function IconAward({award}) {
  return (
    <li 
      className="hero__awards-item"
    >
      <picture className="hero__awards-icon">
        <img src={award.icon} alt="" />
      </picture>
      <div className="hero__awards-name">
        {award.name}
      </div>
      <div className="hero__awards-date" dangerouslySetInnerHTML={{__html: formatDate(award.date)}}>
      </div>
    </li>
  )
}

export default IconAward
